import React, { Component } from 'react';
import { Dialog, DialogTitle, TextField, DialogActions, Button } from '@material-ui/core';

function empty(text) {
  return text.trim().length === 0;
}

export class EditPasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      differentPasswordsError: false
    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit() {
    let {password, confirmPassword, onSubmit} = this.props;
    if (password !== confirmPassword) {
      this.setState({differentPasswordsError: true});
    } else {
      this.setState({differentPasswordsError: false});
      onSubmit();
    }
  }

  render() {
    const {
      open,
      password,
      confirmPassword,
      onChangePassword,
      onChangeConfirmPassword,
      onClose
    }  = this.props;

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>Alterar Senha</DialogTitle>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 10,
          paddingRight: 10
        }}>
          <TextField
            label="Senha"
            placeholder="Senha"
            value={password}
            onChange={onChangePassword}
            type="password"
          />
          <TextField
            label="Confirmar Senha"
            placeholder="Confirmação de Senha"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            type="password"
          />
          <div style={{
            height: 15,

            fontSize: 12,
            color: 'red',

            marginTop: 10,
          }}>
            {
              this.state.differentPasswordsError ? (
                'Senha e confirmação não coincidem'
              ) : ''
            }
          </div>
        </div>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={empty(password) || empty(confirmPassword)} onClick={this.handleOnSubmit} color="primary" autoFocus>
            Alterar senha
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}