
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

import { setToken } from './token';

import client from './api-client';

import api from './api';

import gql from 'graphql-tag';

const AUTH_INTERNAL_USER = gql`
mutation AuthInternalUser($email: String!, $password: String!)  {
  authenticateInternalUser(input: {email: $email, password: $password}) {
    token
    id
  }
}`;

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      email: '',
      password: ''
    };
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);    
  }

  onEmailChange(event) {
    this.setState({
      email: event.target.value
    });
  }

  onPasswordChange(event) {
    this.setState({
      password: event.target.value
    });
  }

  async handleSubmit() {
    const { email, password } = this.state;
    let result;
    console.log("Env", process.env.REACT_APP_API_HOST);
    try {
      result = await api.login(email, password);
      window.localStorage.setItem('token', result.token);
      this.props.history.push('/app');
    } catch(e) {
      console.log(e);
      alert('Email/senha inválidos');
      return;
    }    
  }
  
  render() {
    const { classes } = this.props;
    const { email, password } = this.state;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img
            src="/logo-fpa.png"
            alt="Logo da Fundação Perseu Abramo"
          />
          <Typography component="h1" variant="h5">
            Loginx
          </Typography>
          <div className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.onEmailChange}
                value={email}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.onPasswordChange}
                value={password}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
            >
              Entrar
            </Button>
          </div>
        </Paper>
      </main>
    );
  }
}


function SignIn(props) {
  
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Login));
// import React, { Component } from 'react';

// import { withRouter } from 'react-router-dom';

// import client from './api-client';

// import gql from 'graphql-tag';

// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.onEmailChange = this.onEmailChange.bind(this);
//     this.onPasswordChange = this.onPasswordChange.bind(this);
//     this.state = {
//       email: '',
//       password: '',
//     };
//   }

//   onEmailChange(event) {
//     this.setState({
//       email: event.target.value
//     });
//   }

//   onPasswordChange(event) {
//     this.setState({
//       password: event.target.value
//     });
//   }

//   async handleSubmit(event) {
//     event.preventDefault();
//     const { email, password } = this.state;
//     let result;
//     try {
//       result = await client.mutate({
//         mutation: gql`
//           mutation  {
//             authenticateInternalUser(input: {email: "${email}", password: "${password}"})
//           }`,
//       });
//       window.localStorage.setItem('kuadro-token', result.data.authenticateInternalUser);
//       this.props.history.push('/app/mensagens');
//     } catch(e) {
//       console.log(e);
//       alert('Email/senha inválidos');
//       return;
//     }
//   }
//   render() {
//     return (
//       <div>
//         <form onSubmit={this.handleSubmit}>
//           <div>
//             <input
//               onChange={this.onEmailChange}
//             ></input>
//           </div>
//           <div>
//             <input
//               onChange={this.onPasswordChange}
//             >
//             </input>
//           </div>
//           <button >
//             Entrar
//           </button>
//         </form>
//       </div>
//     );
//   }
// }

// export default withRouter(App);
