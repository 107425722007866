import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import api from './api';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment-timezone';

import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class InternalUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      internalUsers: [],
    };
    this.handleNewUserClick = this.handleNewUserClick.bind(this);
  }

  componentDidMount() {
    this.loadInternalUsers();
  }

  async loadInternalUsers() {
    let resp = await api.getInternalUsers();
    this.setState({
      internalUsers: resp.internal_users,
      loading: false
    });
  }

  handleNewUserClick() {
    this.props.history.push('/app/usuarios-internos/novo');
  }

  fillZero(n) {
    return ('00'+n).slice(-2)
  }

  formatDate(dateString) {
    let date = new Date(dateString);
    return (
      this.fillZero(date.getDate()) + '/' +
      this.fillZero(date.getMonth() + 1) + '/' +
      this.fillZero(date.getFullYear()) + ' ' +
      this.fillZero(date.getHours()) + ':' +
      this.fillZero(date.getMinutes())
    );
  }

  render() {
    let { internalUsers, loading } = this.state;
    if(loading) {
      return (
        <Grid container justify="center" alignItems="center"><CircularProgress></CircularProgress></Grid>
      );
    }

    
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Data de criação</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {internalUsers.map(user => {
              return (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">{user.id}</TableCell>
                  <TableCell align="left">{user.name}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">{this.formatDate(user.created_at)}</TableCell>
                  <TableCell align="left"><Tooltip title="Editar usuário"><Link to={`/app/usuarios-internos/${user.id}`}><EditIcon style={{color: "#000"}}/></Link></Tooltip></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={this.handleNewUserClick}
        >
          Novo usuário interno
        </Button>
      </Fragment>
    );
  }
}

export default withRouter(InternalUsers);
