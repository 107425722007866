import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Paper, Typography} from '@material-ui/core';
import api from '../api';
import Questions from './Questions';
import Details from './Details';
import Respodents from './Respondents';

import { sizing } from '@material-ui/system';
import Answers from './Answers';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

});

class EditPoll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      poll: {
        name: '',
        active: false
      },
      originalPoll: {
        name: '',
        active: false
      },
      hasBatch: false,
      batch: {
        name: '',
        send_at: ''
      },
      newBatch: null,
      answersDownloadLink: null,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handlePollNameChange = this.handlePollNameChange.bind(this);
    this.handlePollActiveChange = this.handlePollActiveChange.bind(this);
    this.pollHasChanged = this.pollHasChanged.bind(this);
    this.updatePoll = this.updatePoll.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.sendBatch = this.sendBatch.bind(this);
    this.deleteBatch = this.deleteBatch.bind(this);
  }

  async componentDidMount() {
    let {poll: { name, active }, answers_download_link: answersDownloadLink } = await api.getPoll(this.props.id);
    console.log("Download link", answersDownloadLink);
    let { poll, originalPoll } = this.state;
    poll = {
      ...poll,
      name,
      active
    };
    originalPoll = {
      ...originalPoll,
      name,
      active
    };
    this.setState({
      poll,
      originalPoll,
      answersDownloadLink,
    });

    await this.loadRespondentsBatch();
  }

  handleTabChange(event, newValue) {
    this.setState({tab: newValue});
  }

  handlePollNameChange(event) {
    let {poll} = this.state;
    poll = {...poll, name: event.target.value};
    this.setState({poll})
  }

  handlePollActiveChange(event) {
    let {poll} = this.state;
    poll = {...poll, active: event.target.checked};
    this.setState({poll})
  }

  pollHasChanged() {
    const { poll, originalPoll } = this.state;
    const hasChanged = poll.name !== originalPoll.name || poll.active !== originalPoll.active;
    return hasChanged;
  }

  async updatePoll() {
    let { poll: { name, active } } = this.state;

    let resp = await api.updatePoll({
      id: this.props.id,
      name,
      active
    });
    console.log("Resp", resp);
  }

  async loadRespondentsBatch() {
    try {
      let {batch} = await api.getRespondentsBatch({
        poll_id: this.props.id,
      });

      batch.send_at = new Date(batch.send_at);

      this.setState({batch, hasBatch: true})
    } catch (err) {
      this.setState({hasBatch: false})
    }
  }

  handleInputFileChange(event) {
    this.setState({
      newBatch: event.target.files[0]
    });
  }

  async sendBatch() {
    let resp = await api.createBatch({
      batch: this.state.newBatch,
      poll_id: this.props.id
    });
    console.log("Resp", resp);
  }

  async deleteBatch() {
    try {
      await api.deleteBatch({
        poll_id: this.props.id
      });
      this.setState({hasBatch: false, batch: {name: '', send_at: ''}});
    } catch (err) {
      alert('Não foi possível excluir o arquivo');
    }
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          height: 'calc(100% - 88px)'
        }}
      >
        <Typography variant="h4" gutterBottom component="h2">
          Pesquisa #{this.props.id}
        </Typography>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#fff'}}>
          <Paper style={{flexBasis: 48}}>
            <Tabs
              value={this.state.tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleTabChange}
            >
              <Tab label="Detalhes" />
              <Tab label="Respondentes" />
              <Tab label="Questionário" />
              <Tab label="Respostas" />
            </Tabs>
          </Paper>
          <Details
            hidden={this.state.tab !== 0}
            poll={this.state.poll}
            handlePollNameChange={this.handlePollNameChange}
            handlePollActiveChange={this.handlePollActiveChange}
            pollHasChanged={this.pollHasChanged}
            onSubmit={this.updatePoll}
          />
          <Respodents
            hidden={this.state.tab !== 1}
            batch={this.state.batch}
            hasBatch={this.state.hasBatch}
            newBatch={this.state.newBatch}
            handleInputFileChange={this.handleInputFileChange}
            sendBatch={this.sendBatch}
            deleteBatch={this.deleteBatch}
          />
          <Questions
            pollId={this.props.id}
            hidden={this.state.tab !== 2}
          />
          <Answers
            pollId={this.props.id}
            answersDownloadLink={this.state.answersDownloadLink}
            hidden={this.state.tab !== 3}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(EditPoll));
