import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import api from './api';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { EditPasswordDialog } from './components/EditPasswordDialog';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

class EditInternalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      originalUser: {
        name: '',
        email: '',
      },
      editingPassword: false,
      loading: false,
    };
    this.userHasChanged = this.userHasChanged.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handlePasswordDialogOpen = this.handlePasswordDialogOpen.bind(this);
    this.handlePasswordDialogClose = this.handlePasswordDialogClose.bind(this);
    this.updateInternalUserPassword = this.updateInternalUserPassword.bind(this);
  }

  async componentDidMount() {
    let {internal_user: { name, email } } = await api.getInternalUser(this.props.id);
    let { user, originalUser } = this.state;
    user = {
      ...user,
      name,
      email
    };
    originalUser = {
      ...originalUser,
      name,
      email,
    };
    this.setState({
      user,
      originalUser,
    });
    console.log("User", user);
  }

  _changeUserField(field, value) {
    let { user } = this.state;
    user = {
      ...user,
      [field]: value
    };
    this.setState({
      user,
    });
  }

  handleNameChange(event) {
    this._changeUserField('name', event.target.value);
  }

  handleEmailChange(event) {
    this._changeUserField('email', event.target.value);
  }

  handlePasswordChange(event) {
    this._changeUserField('password', event.target.value);
  }

  handleConfirmPasswordChange(event) {
    this._changeUserField('confirmPassword', event.target.value);
  }

  handlePasswordDialogOpen() {
    this.setState({
      editingPassword: true,
      user: {...this.state.user, password: '', confirmPassword: '' }
    });
  }

  handlePasswordDialogClose() {
    this.setState({
      editingPassword: false,
    });
  }

  userHasChanged() {
    const { user, originalUser } = this.state;
    const hasChanged = user.name !== originalUser.name || user.email !== originalUser.email;
    return hasChanged;
  }

  async updateInternalUser() {
    let { user: { name, email, password } } = this.state;
    
    let resp = await api.updateInternalUser({
      name,
      email,
      password
    });
    console.log("Resp", resp);
  }

  async updateInternalUserPassword() {
    const { user: { name, email, password } } = this.state;
    const { id } = this.props; 
    this.setState({
      loading: true,
    }, async () => {
      let resp = await api.updateInternalUserPassword({
        id,
        name,
        email,
        password
      });

      let { user } = this.state;
      this.setState({
        loading: false,
        editingPassword: false,
        user: {
          ...user,
          password: '',
          confirmPassword: '',
        }
      });
      alert('Senha alterada com sucesso');
    });
  }

  render() {
    const { classes } = this.props;
    const { user: { name, email, password, confirmPassword }, editingPassword, loading } = this.state;

    if(loading) {
      return (
        <Grid container justify="center" alignItems="center"><CircularProgress></CircularProgress></Grid>
      );
    }

    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <Typography variant="h4" gutterBottom component="h2">
          Usuário Interno #{this.props.id}
        </Typography>
        <EditPasswordDialog
          open={editingPassword}
          password={password}
          confirmPassword={confirmPassword}
          onChangePassword={this.handlePasswordChange}
          onChangeConfirmPassword={this.handleConfirmPasswordChange}
          onSubmit={this.updateInternalUserPassword}
          onClose={this.handlePasswordDialogClose}
        />
        <TextField
          label="Nome"
          placeholder="Nome"
          value={this.state.user.name}
          onChange={this.handleNameChange}
        />
        <TextField
          label="Email"
          placeholder="Email"
          value={this.state.user.email}
          onChange={this.handleEmailChange}
        />
        <div style={{display: "flex", flexDirection: "row"}}>
          <Button
            className={classes.margin}
            color="primary"
            variant="contained"
            size="large"
            onClick={this.createInternalUser}
            disabled={!this.userHasChanged()}
          >
            Salvar
          </Button>
          <Button
            className={classes.margin}
            color="primary"
            variant="contained"
            size="large"
            onClick={this.handlePasswordDialogOpen}
          >
            Alterar senha
          </Button>
        </div>

      </div>
    );
  }
}

export default withRouter(withStyles(styles)(EditInternalUser));
