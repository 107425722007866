import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import api from './api';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

function empty(text) {
  return text.trim().length === 0;
}

class NewPollster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pollster: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: ''
      },
      emptyFieldError: false,
      differentPasswordsError: false
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordConfirmationChange = this.handlePasswordConfirmationChange.bind(this);
    this.createPollster = this.createPollster.bind(this);
  }

  _changePollsterField(field, value) {
    let { pollster, emptyFieldError, differentPasswordsError } = this.state;
    pollster = {
      ...pollster,
      [field]: value
    };

    if (emptyFieldError && !empty(pollster.name) && !empty(pollster.email) && !empty(pollster.password) && !empty(pollster.passwordConfirmation)) {
      emptyFieldError = false;
    }

    if (differentPasswordsError && pollster.password === pollster.passwordConfirmation) {
      differentPasswordsError = false;
    }

    this.setState({
      pollster,
      emptyFieldError,
      differentPasswordsError
    });
  }

  handleNameChange(event) {
    this._changePollsterField('name', event.target.value);
  }

  handleEmailChange(event) {
    this._changePollsterField('email', event.target.value);
  }

  handlePasswordChange(event) {
    this._changePollsterField('password', event.target.value);
  }

  handlePasswordConfirmationChange(event) {
    this._changePollsterField('passwordConfirmation', event.target.value);    
  }

  async createPollster() {
    let { pollster: { name, email, password, passwordConfirmation } } = this.state;

    if (empty(name) || empty(email) || empty(password) || empty(passwordConfirmation)) {
      this.setState({emptyFieldError: true});
    } else if (password !== passwordConfirmation) {
      this.setState({differentPasswordsError: true});
    } else {
      let resp = await api.createPollster({
        name,
        email,
        password
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {pollster} = this.state;

    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <Typography variant="h4" gutterBottom component="h2">
          Novo Pesquisador
        </Typography>
        <TextField
          label="Nome"
          placeholder="Nome"
          value={pollster.name}
          onChange={this.handleNameChange}
        />
        <TextField
          label="Email"
          placeholder="Email"
          value={pollster.email}
          onChange={this.handleEmailChange}
        />
        <TextField
          label="Senha"
          placeholder="Senha"
          value={pollster.password}
          onChange={this.handlePasswordChange}
          type="password"
        />
        <TextField
          label="Confirmar Senha"
          placeholder="Confirmação de Senha"
          value={pollster.passwordConfirmation}
          onChange={this.handlePasswordConfirmationChange}
          type="password"
        />
                <div style={{
          height: 15,

          fontSize: 12,
          color: 'red',

          marginTop: 10,
          marginBottom: 10
        }}>{
          this.state.emptyFieldError ? (
            'Preencha todos os campos'
          ) : this.state.differentPasswordsError ? (
            'Senha e confirmação não coincidem'
          ) : ''
        }</div>
        <Button
          className={classes.margin}
          color="primary"
          variant="contained"
          size="large"
          onClick={this.createPollster}
        >
          Criar
        </Button>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(NewPollster));