import axios from 'axios';

import { getToken, setToken } from './token';

import download from 'downloadjs';

const API_HOST = process.env.REACT_APP_API_HOST;



const api = {};

const apiPath = path => {
  return `${API_HOST}${path}`
}

api.login = async (email, password) => {
  try {
    let resp = await axios.post(apiPath('/internal/auth'), {
      email,
      password
    })
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.getInternalUsers = async () => {
  try {
    let resp = await axios.get(apiPath('/internal/internal-users'), {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.getInternalUser = async (id) => {
  try {
    let resp = await axios.get(apiPath(`/internal/internal-users/${id}`), {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.createInternalUser = async ({name, email, password}) => {
  try {
    let resp = await axios.post(apiPath('/internal/internal-users'), {
      name,
      email,
      password
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.updateInternalUser = async ({id, name, email}) => {
  try {
    let resp = await axios.put(apiPath(`/internal/internal-users/${id}`), {
      name,
      email,
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.updateInternalUserPassword = async ({id, password}) => {
  try {
    let resp = await axios.put(apiPath(`/internal/internal-users/${id}/password`), {
      password: password,
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    return resp.data;
  } catch(e) {
    throw e;
  }
}

/**
 * Pollster requests
 */

api.getPollsters = async () => {
  try {
    let resp = await axios.get(apiPath('/internal/pollsters'), {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.createPollster = async ({name, email, password}) => {
  try {
    let resp = await axios.post(apiPath('/internal/pollsters'), {
      name,
      email,
      password
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.getPollster = async (id) => {
  try {
    let resp = await axios.get(apiPath(`/internal/pollsters/${id}`), {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.updatePollster = async ({id, name, email}) => {
  try {
    let resp = await axios.put(apiPath(`/internal/pollsters/${id}`), {
      name,
      email,
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.updatePollsterPassword = async ({id, password}) => {
  try {
    let resp = await axios.put(apiPath(`/internal/pollsters/${id}/password`), {
      password: password,
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    return resp.data;
  } catch(e) {
    throw e;
  }
}

/**
 * Poll requests
 */

api.getPolls = async () => {
  try {
    let resp = await axios.get(apiPath('/internal/polls'), {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.getPoll = async (id) => {
  try {
    let resp = await axios.get(apiPath(`/internal/polls/${id}`), {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.createPoll = async ({name}) => {
  try {
    let resp = await axios.post(apiPath('/internal/polls'), {
      name,
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.updatePoll = async ({id, name, active}) => {
  try {
    let resp = await axios.put(apiPath(`/internal/polls/${id}`), {
      name: name,
      active: active,
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    return resp.data;
  } catch(e) {
    throw e;
  }
}

/**
 * Retorna objeto {batch: {name, send_at}}
 */
api.getRespondentsBatch = async ({poll_id}) => {
  try {
    let resp = await axios.get(apiPath(`/internal/polls/${poll_id}/respondents/batch`), {
      headers: {
        'X-Auth': getToken()
      }
    });
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.createBatch = async ({poll_id, batch}) => {
  try {
    let formData = new FormData();
    formData.append("batch", batch);
    let resp = await axios.put(apiPath(`/internal/polls/${poll_id}/respondents/batch`),
    formData, {
      headers: {
        'X-Auth': getToken(),
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log("resp", resp.data);
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.deleteBatch = async ({poll_id}) => {
  try {
    let resp = await axios.delete(apiPath(`/internal/polls/${poll_id}/respondents/batch`), {
      headers: {
        'X-Auth': getToken()
      }
    });
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.getPollQuestions = async ({poll_id}) => {
  try {
    let resp = await axios.get(apiPath(`/internal/polls/${poll_id}/questions`), {
      headers: {
        'X-Auth': getToken()
      }
    });
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.updatePollQuestions = async ({id, multipleChoiceQuestions, writtenQuestions, checkboxQuestions}) => {
  try {
    let resp = await axios.put(apiPath(`/internal/polls/${id}/questions`), {
      id,
      multipleChoiceQuestions,
      writtenQuestions,
      checkboxQuestions
    }, {
      headers: {
        'X-Auth': getToken()
      }
    });
    return resp.data;
  } catch(e) {
    throw e;
  }
}

api.getAnswers = async ({
  target_question_id,
  base_question_id,
  weighting,
  filter_question_id,
  filter_alternative_id,
  poll_id,
}) => {
  try {
    let resp = await axios.post(apiPath(`/internal/polls/${poll_id}/answers/statistics`),
      {
        target_question_id,
        base_question_id,
        weighting,
        filter_question_id,
        filter_alternative_id
      },
      {
        headers: {
          'X-Auth': getToken()
        },
      })
    return resp.data;
  } catch (e) {

  }
}

api.getAnswersFile = downloadLink => {
  window.fetch(apiPath(downloadLink),{
    headers: {
      'X-Auth': getToken()
    }
  })
    .then(res => {
      return res.blob();
    })
    .then(res => {
      let fname = `respostas.csv`;
      download(res, fname);
    })
    .catch(err => {
      console.log("Err", err);
      alert('Erro ao baixar o arquivo');
    });
}

export default api;
