import React, { Component, Fragment } from 'react';
import { Typography, Table, TableRow, TableCell, Button, TableBody, Paper, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

class Respodents extends Component {
  fillZero(n) {
    return ('00'+n).slice(-2)
  }

  formatDate(dateString) {
    let date = new Date(dateString);
    return (
      this.fillZero(date.getDate()) + '/' +
      this.fillZero(date.getMonth() + 1) + '/' +
      this.fillZero(date.getFullYear()) + ' ' +
      this.fillZero(date.getHours()) + ':' +
      this.fillZero(date.getMinutes())
    );
  }

  render() {
    return (
      <div hidden={this.props.hidden}>
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: 'center',

          paddingTop: 20,
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 40,

          flex: 1,
        }}>
          {
            this.props.hasBatch ? (
              <div style={{flex: 1, width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography variant="h5">
                    Respondentes
                  </Typography>
                  <Tooltip title="Remover Respondentes">
                    <IconButton onClick={this.props.deleteBatch}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <Paper style={{width: 500}}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography variant="body2">Nome</Typography></TableCell>
                        <TableCell>{this.props.batch.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><Typography variant="body2">Data de envio</Typography></TableCell>
                        <TableCell>{this.formatDate(this.props.batch.send_at)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            ) : (
              <Fragment>
                <div style={{width: '100%'}}>
                  <Typography variant="h5" component="h6">
                    Novo arquivo
                  </Typography>
                  {
                    this.props.newBatch ? (
                      <Typography variant="body1">{this.props.newBatch.name}</Typography>
                    ) : (
                      <Typography variant="body1">Nenhum arquivo selecionado</Typography>
                    )
                  }
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 700}}>
                    <Button
                      component="label"
                      style={{width: 300}}
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={this.props.handleInputFileChange}
                    />
                      Selecionar
                    </Button>
                    <Button
                      style={{width: 300}}
                      color="primary"
                      variant="contained"
                      size="large"
                      disabled={this.props.newBatch == null}
                      onClick={this.props.sendBatch}
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </Fragment>
            )
          }
        </div>
      </div>
    );
  }
}

export default Respodents;