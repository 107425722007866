import React, {Component, Fragment} from 'react';
import { Link, withRouter } from 'react-router-dom';
import api from './api';
import { Table, TableHead, TableRow, TableCell, Tooltip, TableBody, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

class Pollsters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pollsters: []
    }

    this.handleNewPollsterClick = this.handleNewPollsterClick.bind(this);
  }

  componentDidMount() {
    this.loadPollsters();
  }

  async loadPollsters() {
    let resp = await api.getPollsters();
    console.log(resp)
    this.setState({
      pollsters: resp.pollsters,
      loading: false
    });
  }

  handleNewPollsterClick() {
    this.props.history.push('/app/pesquisadores/novo');
  }

  render() {
    let { pollsters, loading } = this.state;
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Data de criação</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pollsters.map(pollster => {
              return (
                <TableRow key={pollster.id}>
                  <TableCell component="th" scope="row">{pollster.id}</TableCell>
                  <TableCell align="left">{pollster.name}</TableCell>
                  <TableCell align="left">{pollster.email}</TableCell>
                  <TableCell align="left">{pollster.created_at}</TableCell>
                  <TableCell align="left">
                    <Tooltip title="Editar pesquisador">
                      <Link to={`/app/pesquisadores/${pollster.id}`}><EditIcon style={{color: "#000"}}/></Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={this.handleNewPollsterClick}
        >
          Novo pesquisador
        </Button>
      </Fragment>
    );
  }
}

export default withRouter(Pollsters);