import React, { Component } from 'react';
import { EditPasswordDialog } from './components/EditPasswordDialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import api from './api';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

class EditPollster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pollster: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      originalPollster: {
        name: '',
        email: '',
      },
      editingPassword: false,
      loading: false,
    };
    this.pollsterHasChanged = this.pollsterHasChanged.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handlePasswordDialogOpen = this.handlePasswordDialogOpen.bind(this);
    this.handlePasswordDialogClose = this.handlePasswordDialogClose.bind(this);
    this.updatePollster = this.updatePollster.bind(this);
    this.updatePollsterPassword = this.updatePollsterPassword.bind(this);
  }

  async componentDidMount() {
    let {pollster: { name, email } } = await api.getPollster(this.props.id);
    let { pollster, originalPollster } = this.state;
    pollster = {
      ...pollster,
      name,
      email
    };
    originalPollster = {
      ...originalPollster,
      name,
      email,
    };
    this.setState({
      pollster,
      originalPollster,
    });
  }

  _changePollsterField(field, value) {
    let { pollster } = this.state;
    pollster = {
      ...pollster,
      [field]: value
    };
    this.setState({
      pollster,
    });
  }

  handleNameChange(event) {
    this._changePollsterField('name', event.target.value);
  }

  handleEmailChange(event) {
    this._changePollsterField('email', event.target.value);
  }

  handlePasswordChange(event) {
    this._changePollsterField('password', event.target.value);
  }

  handleConfirmPasswordChange(event) {
    this._changePollsterField('confirmPassword', event.target.value);
  }

  handlePasswordDialogOpen() {
    this.setState({
      editingPassword: true,
    });
  }

  handlePasswordDialogClose() {
    this.setState({
      editingPassword: false,
      pollster: {...this.state.pollster, password: '', confirmPassword: '' }
    });
  }

  pollsterHasChanged() {
    const { pollster, originalPollster } = this.state;
    const hasChanged = pollster.name !== originalPollster.name || pollster.email !== originalPollster.email;
    return hasChanged;
  }

  async updatePollster() {
    let { pollster: { name, email } } = this.state;
    let { id } = this.props;
    let resp = await api.updatePollster({
      id,
      name,
      email,
    });
    console.log("Resp", resp);
  }

  async updatePollsterPassword() {
    const { pollster: { name, email, password } } = this.state;
    const { id } = this.props; 

    this.setState({
      loading: true,
    }, async () => {
      try {
        let resp = await api.updatePollsterPassword({
          id,
          name,
          email,
          password
        });
        let { pollster } = this.state;
        this.setState({
          loading: false,
          editingPassword: false,
          pollster: {
            ...pollster,
            password: '',
            confirmPassword: '',
          }
        });
        alert('Senha alterada com sucesso');
      } catch (err) {
        this.setState({
          loading: false,
          editingPassword: false,
        });
        alert('Erro ao alterar senha');
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { pollster: { name, email, password, confirmPassword }, editingPassword, loading } = this.state;

    if(loading) {
      return (
        <Grid container justify="center" alignItems="center"><CircularProgress></CircularProgress></Grid>
      );
    }

    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <Typography variant="h4" gutterBottom component="h2">
          Pesquisador #{this.props.id}
        </Typography>
        <EditPasswordDialog
          open={editingPassword}
          password={password}
          confirmPassword={confirmPassword}
          onChangePassword={this.handlePasswordChange}
          onChangeConfirmPassword={this.handleConfirmPasswordChange}
          onSubmit={this.updatePollsterPassword}
          onClose={this.handlePasswordDialogClose}
        />
        <TextField
          label="Nome"
          placeholder="Nome"
          value={this.state.pollster.name}
          onChange={this.handleNameChange}
        />
        <TextField
          label="Email"
          placeholder="Email"
          value={this.state.pollster.email}
          onChange={this.handleEmailChange}
        />
        <div style={{display: "flex", flexDirection: "row"}}>
          <Button
            className={classes.margin}
            color="primary"
            variant="contained"
            size="large"
            onClick={this.updatePollster}
            disabled={!this.pollsterHasChanged()}
          >
            Salvar
          </Button>
          <Button
            className={classes.margin}
            color="primary"
            variant="contained"
            size="large"
            onClick={this.handlePasswordDialogOpen}
          >
            Alterar senha
          </Button>
        </div>

      </div>
    );
  }
}

export default withRouter(withStyles(styles)(EditPollster));
