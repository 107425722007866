import React, {Component} from 'react';
import { TextField, Button, Switch, FormControlLabel } from '@material-ui/core';

class Details extends Component {
  render() {
    return (
      <div hidden={this.props.hidden}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",

            padding: 40,

            height: 200,
          }}
        >
          <TextField
            style={{width: 200}}
            label="Nome"
            placeholder="Nome"
            value={this.props.poll.name}
            onChange={this.props.handlePollNameChange}
          />
          <FormControlLabel
            control={<Switch color="primary" checked={this.props.poll.active} onChange={this.props.handlePollActiveChange} name="checkedA" />}
            label={this.props.poll.active ? 'Ativa' : 'Inativa'}
          />
          <Button
            style={{width: 200}}
            color="primary"
            variant="contained"
            size="large"
            disabled={!this.props.pollHasChanged()}
            onClick={this.props.onSubmit}
          >
            Salvar mudanças
          </Button>
        </div>
      </div>
    );
  }
}

export default Details;
