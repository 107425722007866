import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import api from './api';
import { Link } from 'react-router-dom';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

function empty(text) {
  return text.trim().length === 0;
}

class NewInternalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: ''
      },
      emptyFieldError: false,
      differentPasswordsError: false,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordConfirmationChange = this.handlePasswordConfirmationChange.bind(this);
    this.createInternalUser = this.createInternalUser.bind(this);
  }

  _changeUserField(field, value) {
    let { user, emptyFieldError, differentPasswordsError } = this.state;
    user = {
      ...user,
      [field]: value
    };

    if (emptyFieldError && !empty(user.name) && !empty(user.email) && !empty(user.password) && !empty(user.passwordConfirmation)) {
      emptyFieldError = false;
    }

    if (differentPasswordsError && user.password === user.passwordConfirmation) {
      differentPasswordsError = false;
    }

    this.setState({
      user,
      emptyFieldError,
      differentPasswordsError
    });
  }

  handleNameChange(event) {
    this._changeUserField('name', event.target.value);
  }

  handleEmailChange(event) {
    this._changeUserField('email', event.target.value);
  }

  handlePasswordChange(event) {
    this._changeUserField('password', event.target.value);
  }

  handlePasswordConfirmationChange(event) {
    this._changeUserField('passwordConfirmation', event.target.value);    
  }

  async createInternalUser() {
    let { user: { name, email, password, passwordConfirmation } } = this.state;
    
    if (empty(name) || empty(email) || empty(password) || empty(passwordConfirmation)) {
      this.setState({emptyFieldError: true});
    } else if (password !== passwordConfirmation) {
      this.setState({differentPasswordsError: true});
    } else {
      let resp = await api.createInternalUser({
        name,
        email,
        password
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <Typography variant="h4" gutterBottom component="h2">
          Novo Usuário Interno
        </Typography>
        <TextField
          label="Nome"
          placeholder="Nome"
          value={this.state.user.name}
          onChange={this.handleNameChange}
        />
        <TextField
          label="Email"
          placeholder="Email"
          value={this.state.user.email}
          onChange={this.handleEmailChange}
        />
        <TextField
          label="Senha"
          placeholder="Senha"
          value={this.state.user.password}
          onChange={this.handlePasswordChange}
          type="password"
        />
        <TextField
          label="Confirmar Senha"
          placeholder="Confirmação de Senha"
          value={this.state.user.passwordConfirmation}
          onChange={this.handlePasswordConfirmationChange}
          type="password"
        />
        <div style={{
          height: 15,

          fontSize: 12,
          color: 'red',

          marginTop: 10,
          marginBottom: 10
        }}>{
          this.state.emptyFieldError ? (
            'Preencha todos os campos'
          ) : this.state.differentPasswordsError ? (
            'Senha e confirmação não coincidem'
          ) : ''
        }</div>
        <Button
          className={classes.margin}
          color="primary"
          variant="contained"
          size="large"
          onClick={this.createInternalUser}
        >
          Criar
        </Button>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(NewInternalUser));
