import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';

import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SendIcon from '@material-ui/icons/Send';
import { Button, Paper, Typography } from '@material-ui/core';
import api from '../api';

class ReadOnlyQuestion extends Component {
  render() {
    return (
      <div
        style={{marginTop: "10px", marginBottom: "10px"}}
        onClick={() => this.props.onSelectQuestion(this.props.index)}
      >
        <div>
          <TextField
            margin="normal"
            value={this.props.body}
            onChange={this.onBodyChange}
            multiline
            disabled
            InputProps={{
              disableUnderline: true
            }}
            fullWidth
          />
        </div>
        {this.props.type === "dissertative" ? <TextField
              label="Texto"
              placeholder="Texto"
              disabled
              margin="normal"
            /> : this.props.alternatives.map((el, idx) => <Alternative key={`alt_key_${idx}`} text={el.body} type={this.props.type}/>)}
      </div>
    );
  }
}

class Question extends Component {
  constructor(props) {
    super(props);
    this.onAlternativeChange = this.onAlternativeChange.bind(this);
  }

  onAlternativeChange(altIdx, body) {
    this.props.onAlternativeChange(this.props.index, altIdx, body);
  }
  render() {
    const { deleteQuestion, onChange } = this.props;
    return (
      <Card
        style={{marginTop: "10px", marginBottom: "10px"}}
      >
        <CardContent>
          <div style={{position: 'relative', display: 'inline-block', width:'100%'}}>
            <TextField
              placeholder="Pergunta"
              margin="normal"
              multiline
              fullWidth
              value={this.props.body}
              onChange={this.props.onBodyChange}
            />
            <Select
              value={this.props.type}
              onChange={onChange}
            >
              <MenuItem value={"multiple_choice"}>Múltipla Escolha</MenuItem>
              <MenuItem value={"checkbox"}>Opções</MenuItem>
              <MenuItem value={"dissertative"}>Escrita</MenuItem>
            </Select>
            {this.props.removable &&
             <Tooltip title="Remover Questão">
               <IconButton
                 style={{position: "absolute", top: 10, right: 0}}
                 onClick={deleteQuestion}
               >
                 <DeleteIcon />
               </IconButton>
             </Tooltip>
            }
          </div>
          { this.props.type !== "dissertative" ? <AlternativesBox
          type={this.props.type}
          alternatives={this.props.alternatives}
          onAlternativeChange={this.onAlternativeChange}
          onNewAlternative={this.props.onNewAlternative}
          deleteAlternative={this.props.deleteAlternative}
                                                          />
            :
            <TextField
              label="Texto"
              placeholder="Texto"
              disabled
              margin="normal"
            />
          }
        </CardContent>

      </Card>
    );
  }
}

class AlternativesBox extends Component {
  render() {
    return (
      <div>
        {this.props.alternatives.map(
          (el, idx) =>
            <Alternative
              type={this.props.type}
              key={idx}
              text={el.body}
              removable={this.props.alternatives.length !== 1}
              selectable
              onChange={this.props.onAlternativeChange}
              index={idx}
              onDelete={() => this.props.deleteAlternative(idx)}/>
          )}
        <div>
          {this.props.type === "multiple_choice" ? <Radio disabled/> : <Checkbox disabled value="checkedD" />}
          <span style={{color: "#bbb", cursor: "pointer"}} onClick={this.props.onNewAlternative}>
            Adicionar opção
          </span>
        </div>
      </div>
    );
  }
}

class Alternative extends Component {
  render() {
    return (
      <div
        style={this.props.selectable && {cursor: "pointer", display: "flex", justifyContent: "space-between"}}
      >
        <div>
          {this.props.type === "multiple_choice" ? <Radio disabled/> : <Checkbox disabled value="checkedD" />}
          <InputBase
            placeholder="Alternativa"
            value={this.props.text}
            onChange={event => this.props.onChange(this.props.index, event.target.value)}
          />

        </div>
        {this.props.selectable && this.props.removable &&
         <Tooltip title="Remover alternativa">
           <IconButton onClick={this.props.onDelete}>
             <CancelIcon/>
           </IconButton>
         </Tooltip>
        }
      </div>
    );
  }
}

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hasChanged: false,
      questions: [],
      selectedQuestionIdx: 0,
      allPollsters: true,
      pollsters: [],
      pollstersSuggestions: [],
      pollster: null,
      showInput: false
    };

    this.onNewQuestion = this.onNewQuestion.bind(this);
    this.onNewAlternative = this.onNewAlternative.bind(this);
    this.onQuestionTypeChange = this.onQuestionTypeChange.bind(this);
    this.onAlternativeChange = this.onAlternativeChange.bind(this);
    this.onBodyChange = this.onBodyChange.bind(this);
    this.deleteSelectedQuestion = this.deleteSelectedQuestion.bind(this);
    this.deleteSelectedQuestionAlternative = this.deleteSelectedQuestion.bind(this);
    this.onSelectQuestion = this.onSelectQuestion.bind(this);
    this.updatePollQuestions = this.updatePollQuestions.bind(this);
  }

  componentDidMount() {
    this.loadQuestions();
  }

  async loadQuestions() {
    try {
      let {questions} = await api.getPollQuestions({
        poll_id: this.props.pollId,
      });

      this.setState({questions, loading: false})
    } catch (err) {
      this.setState({loading: false})
    }
  }

  onNewQuestion(event) {
    this.setState((state, props) => {
      let newQuestion = {
        body: `Pergunta ${state.questions.length + 1}`,
        position: state.questions.length+1,
        type: "multiple_choice",
        alternatives: [
          {
            body: 'Opção 1',
            position: 1
          }
        ]
      };

      var objDiv = document.getElementById('questions-container');
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;

      return {
        questions: state.questions.concat(newQuestion),
        selectedQuestionIdx: state.questions.length,
        hasChanged: true
      };
    });
  }

  onNewAlternative(event) {
    this.setState((state, props) => {
      let questions = state.questions;
      let selectedQuestion = questions[state.selectedQuestionIdx];
      let newAlt = {
        body: `Opção ${selectedQuestion.alternatives.length+1}`,
        position: selectedQuestion.alternatives.length+1
      };
      selectedQuestion.alternatives = selectedQuestion.alternatives.concat([newAlt]);
      return {
        questions: questions,
        hasChanged: true
      };
    });
  }

  onQuestionTypeChange(event) {
    this.setState((state, props) => {
      const { questions, selectedQuestionIdx } = state;
      questions[selectedQuestionIdx].type = event.target.value;
      this.setState({
        questions,
        hasChanged: true
      });
    });
  }

  onAlternativeChange(questionIdx, alternativeIdx, body) {
    this.setState((state, props) => {
      let questions = state.questions;
      questions[questionIdx].alternatives[alternativeIdx].body = body;
      return {
        questions,
        hasChanged: true
      };
    });
  }

  deleteSelectedQuestion() {
    this.setState((state, props) => {
      const { questions, selectedQuestionIdx } = state;
      questions.splice(selectedQuestionIdx, 1);
      return {
        questions,
        hasChanged: true
      };
    });
  }

  deleteSelectedQuestionAlternative(alternativeIndex) {
    this.setState((state, props) => {
      const { questions, selectedQuestionIdx } = state;
      questions[selectedQuestionIdx].alternatives.splice(alternativeIndex, 1);
      return {
        questions,
        hasChanged: true
      };
    });
  }

  onBodyChange(event) {
    let questions = this.state.questions;
    questions[this.state.selectedQuestionIdx].body = event.target.value;
    this.setState({
      questions: questions,
      hasChanged: true
    });
  }

  onSelectQuestion(index) {
    this.setState({
      selectedQuestionIdx: index
    });
  }

  _filterQuestionsWithAlternative(qType) {
    return this.state.questions.filter(q => q.type === qType).map((el, idxQ) => {
          let payload = {
            body: el.body,
            position: el.position,
            alternatives: el.alternatives.map((x, idx) => {
              let p = {
                body: x.body,
                position: x.position
              };
              return p;
            })
          };
          return payload;
    });
  }

  async updatePollQuestions() {
    let multipleChoiceQuestions = this._filterQuestionsWithAlternative("multiple_choice");
    let checkboxQuestions = this._filterQuestionsWithAlternative("checkbox");
    let writtenQuestions = this.state.questions.filter(q => q.type === "dissertative").map(el => {
      let payload = {
        body: el.body,
        position: el.position
      };
      return payload;
    });

    let variables = {
      id: this.props.pollId,
      multipleChoiceQuestions: multipleChoiceQuestions,
      writtenQuestions: writtenQuestions,
      checkboxQuestions: checkboxQuestions
    };

    console.log(variables);

    try {
      await api.updatePollQuestions(variables);
      this.setState({hasChanged: false});
    } catch (err) {
      alert('Não foi possível salvar as questões');
      this.setState({hasChanged: false});
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          Carregando
        </div>
      )
    }
    return (
      <div
        hidden={this.props.hidden}
        style={{
          flex: 1,

          display: this.props.hidden ? 'none' : 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',

          height: 68,

          paddingLeft: 40,
          paddingTop: 20
        }}>
          <Typography variant="h5">
            Questões
          </Typography>
        </div>
        <div id="questions-container" style={{
          flexGrow: 1,

          height: 0,

          overflow: 'auto',

          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 20
        }}>
          {
          this.state.questions.map((q,idx) => {
            if (idx === this.state.selectedQuestionIdx) {
              return (
                <Question
                  key={idx}
                  body={q.body}
                  type={q.type}
                  alternatives={q.alternatives}
                  index={idx}
                  onNewAlternative={this.onNewAlternative}
                  onAlternativeChange={this.onAlternativeChange}
                  removable={this.state.questions.length !== 1}
                  deleteQuestion={this.deleteSelectedQuestion}
                  deleteAlternative={this.deleteSelectedQuestionAlternative}
                  onBodyChange={this.onBodyChange}
                  onChange={this.onQuestionTypeChange}
                  style={{marginTop: "50px", marginBottom: "10px"}}
                />
              );
            }
            return (
              <ReadOnlyQuestion
                body={q.body}
                key={idx}
                type={q.type}
                alternatives={q.alternatives}
                onSelectQuestion={this.onSelectQuestion}
                style={{marginTop: "10px", marginBottom: "10px"}}
                index={idx}
              />
            );
          })}
        </div>
        <div style={{
          flexBasis: 64,

          paddingRight: 20,
          paddingLeft: 20,

          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Tooltip title="Adicionar Questão">
            <Fab color="primary" aria-label="Add" onClick={this.onNewQuestion}>
              <AddIcon />
            </Fab>
          </Tooltip>
          <Button
            style={{height: 48, width: 200}}
            color="primary"
            variant="contained"
            size="large"
            onClick={this.updatePollQuestions}
            disabled={!this.state.hasChanged}
          >
            Salvar Mudanças
          </Button>
        </div>
      </div>
    )
  }
}

export default Questions;
