import React, {Component, Fragment} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Table, TableHead, TableRow, TableCell, Tooltip, TableBody, Button, DialogContent, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Dialog, DialogTitle, TextField, DialogActions } from '@material-ui/core';
import api from '../api';
import moment from 'moment';

function empty(text) {
  return text == null ||text == undefined || text.length === 0 || text === '';
}

class NewPoll extends Component {
  render() {
    const {
      open,
      name,
      onChangeName,
      onSubmit,
      onClose
    }  = this.props;
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>Nova Pesquisa</DialogTitle>
        <div style={{
            paddingLeft: 20,
            paddingRight: 20
          }}>
          <TextField
            label="Nome"
            placeholder="Nome"
            value={name}
            onChange={onChangeName}
          />
          <div style={{
            height: 28,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            color: 'red',
            fontSize: 11
          }}>
            {this.props.createError ? 'Não foi possível criar a pesquisa' : ''}
          </div>
        </div>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={empty(name)} onClick={onSubmit} color="primary" autoFocus>
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

class Polls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      polls: [],
      creatingPoll: false,
      createError: false,
      newPoll: {
        name: '',
        created_at: ''
      }
    }

    this.handleNewPollNameChange = this.handleNewPollNameChange.bind(this);
    this.handleNewPollDialogOpen = this.handleNewPollDialogOpen.bind(this);
    this.handleNewPollDialogClose = this.handleNewPollDialogClose.bind(this);
    this.createPoll = this.createPoll.bind(this);
  }

  componentDidMount() {
    this.loadPolls();
  }

  async loadPolls() {
    try {
      let resp = await api.getPolls();

      console.log(resp)

      this.setState({
        polls: resp.polls,
        loading: false
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleNewPollNameChange(event) {
    let {newPoll} = this.state;
    newPoll = {...newPoll, name: event.target.value};
    this.setState({newPoll})
  }

  handleNewPollDialogOpen() {
    this.setState({
      creatingPoll: true,
    });
  }

  handleNewPollDialogClose() {
    this.setState({
      creatingPoll: false,
    });
  }

  async createPoll() {
    let { newPoll: { name } } = this.state;

    try {
      let resp = await api.createPoll({
        name,
      });
      console.log("Resp", resp);
      this.setState({creatingPoll: false, createError: false}, () => this.loadPolls());
    } catch (err) {
      this.setState({createError: true});
      console.log(err);
    }
  }

  render() {
    let {polls} = this.state;

    if (this.state.loading) {
      return <div></div>
    }

    return (
      <Fragment>
        <NewPoll
          open={this.state.creatingPoll}
          name={this.state.newPoll.name}
          onChangeName={this.handleNewPollNameChange}
          onSubmit={this.createPoll}
          onClose={this.handleNewPollDialogClose}
          createError={this.state.createError}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">Data de criação</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {polls.map(poll => {
              return (
                <TableRow key={poll.id}>
                  <TableCell component="th" scope="row">{poll.id}</TableCell>
                  <TableCell align="left">{poll.name}</TableCell>
                  <TableCell align="left">{moment(poll.created_at).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm")}</TableCell>
                  <TableCell align="left">
                    <Tooltip title="Editar pesquisa">
                      <Link to={`/app/pesquisas/${poll.id}`}>
                        <IconButton>
                          <EditIcon/>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={this.handleNewPollDialogOpen}
        >
          Nova pesquisa
        </Button>
      </Fragment>
    );
  }
}

export default withRouter(Polls);
