import React, { Component, Fragment } from 'react';
import { List, ListItem, Typography, Divider, Button, Tooltip, IconButton, Fab, Paper, Checkbox, ListItemIcon, ListItemText, TableHead, TableBody, TableRow, TableCell, Table, InputLabel, MenuItem, FormControl, Select, FormControlLabel, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import api from '../api';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const alphabet = ("abcdefghijklmnopqrstuvwxyz").split("");

const styles = {
  tableCell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',

    paddingRight: 0,
    paddingLeft: 0,

    minWidth: 96,

    textAlign: 'center'
  },
  tableCellTitle: {
    fontSize: 11,
    fontWeight: '500',
    color: '#000',

    paddingLeft: 20,
    paddingRight: 20
  },
  tableCellFixed: {
    top: 0,
    left: 0,

    position: 'sticky',

    backgroundColor: '#fff',

    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  }
};

class QuestionsWeighting extends Component {
  onChange = (e, alternativeId) => {
    this.props.handleWeightChange(e.target.value, alternativeId);
    this.props.checkHasEmptyField();
    this.props.checkOnlyNumbers();
    this.props.checkSumIsOneHundred();
  }

  render() {
    let {
      questions,
      questionWeighting,
      selectedWeightingQuestionIdx
    } = this.props;

    return (
      <Paper style={{
        flex: 1,

        paddingLeft: 10,
        paddingRight: 10,

        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{
          flexBasis: 100,
          paddingLeft: 10,
          paddingRight: 10
        }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.weighting}
                onChange={this.props.handleWeightingChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Ponderação"
          />
          <Divider />
          <Select
            id="select-weighting-question"
            value={selectedWeightingQuestionIdx}
            onChange={this.props.handleChangeWeightingQuestion}
            style={{marginTop: 10, marginBottom: 10, width: '100%', maxWidth: 200}}
            disabled={!this.props.weighting}
          >
            {
              questions.map((question, idx) =>
                <MenuItem key={question.id} value={idx}>{question.position}</MenuItem>
              )
            }
          </Select>
          <Divider />
        </div>
        <div style={{
          flexGrow: 1,
          height: 0,
          overflow: 'auto'
        }}>
          {
              selectedWeightingQuestionIdx === -1 ? (
                <div></div>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow style={{height: 32}}>
                      <TableCell style={{backgroundColor: 'white', position: 'sticky', top: 0}}>Alternativa</TableCell>
                      <TableCell style={{backgroundColor: 'white', zIndex: 99, position: 'sticky', top: 0}}>Peso (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      questionWeighting.alternatives.map(alt => 
                        <TableRow key={alt.id} style={{height: 32}}>
                          <TableCell>{alt.body}</TableCell>
                          <TableCell>
                            <TextField
                              style={{width: 50}}
                              placeholder="Peso"
                              value={alt.weight}
                              onChange={(e) => this.onChange(e, alt.id)}
                              disabled={!this.props.weighting}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
              </Table>
              )
          }
        </div>
        <div style={{
          flexBasis: 32,

          fontSize: 12,

          color: 'red'
        }}>
          {
            this.props.weighting ? (
              this.props.onlyNumberError ? (
                'Peso inválido'
              ) : (
                this.props.emptyFieldError ? (
                  'Preencha todos os pesos'
                ) : (
                  this.props.sumIsOneHundredError ? (
                    'A soma dos pesos deve ser 100'
                  ) : null
                )
              )
            ) : null
          }
        </div>
      </Paper>
    );
  }
}

class QuestionFilter extends Component {
  render() {
    return (
      <Paper style={{
        height: 0,
        flexBasis: 200,
        overflow: 'auto',
        paddingLeft: 10,
        paddingRight: 10,

        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{
          flexBasis: 64,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.filtering}
                onChange={this.props.handleFilteringChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Filtro"
          />
          <Divider />
        </div>
        <div style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <div style={{flex: 1}}>
            <SelectWithTitle
              disabled={!this.props.filtering}
              label="Questão"
              value={this.props.selectedFilteredQuestionIdx}
              onChange={this.props.handleChangeFilteredQuestion}
              options={this.props.questions}
              labels={this.props.questions.map(q => q.position)}
            />
          </div>
          <div style={{flex: 1}}>
            <SelectWithTitle
              disabled={!this.props.filtering} 
              label="Alternativa"
              value={this.props.selectedFilteredAlternativeIdx}
              onChange={this.props.handleChangeFilteredAlternative}
              options={
                this.props.selectedFilteredQuestionIdx === -1 ?
                [] :
                this.props.questions[this.props.selectedFilteredQuestionIdx].alternatives
              }
              labels={
                this.props.selectedFilteredQuestionIdx === -1 ?
                [] :
                this.props.questions[this.props.selectedFilteredQuestionIdx].alternatives.map((q, idx) => alphabet[idx])
              }
            />
          </div>
        </div>
      </Paper>
    );
  }
}

function StatisticsCell({showPercentage, showCount}) {
  return (
    <TableCell style={{...styles.tableCellFixed, ...styles.tableCell, ...styles.tableCellTitle}}>
      {
        !showPercentage
        ?
        'Contagem'
        : (
          !showCount
          ?
          '%'
          :
          'Contagem (%)'
        )
      }
    </TableCell>
  )
}

class AnswersTable extends Component {
  render() {
    return (
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Paper style={{
          height: 0,
          flexGrow: 1,
          overflow: 'auto',
        }}>
          <Table id="answers-table">
            <TableHead>
              <TableRow>
              {
                this.props.baseQuestionAlternatives.length === 0 ? (
                  <Fragment>
                    <TableCell style={{...styles.tableCellFixed, ...styles.tableCell, ...styles.tableCellTitle}}>
                      Alternativa
                    </TableCell>
                    <StatisticsCell
                      showPercentage={this.props.showPercentage}
                      showCount={this.props.showCount}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <StatisticsCell
                      showPercentage={this.props.showPercentage}
                      showCount={this.props.showCount}
                    />
                    {
                      this.props.baseQuestionAlternatives.map((a, idx) =>
                        <TableCell key={idx} style={{...styles.tableCellFixed, ...styles.tableCell, ...styles.tableCellTitle}}>
                          {a.label}
                        </TableCell>
                      )
                    }
                  </Fragment>
                )
              }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.baseQuestionAlternatives.length === 0 ? (
                  this.props.targetQuestionAlternatives.map((a, idx) =>
                    <TableRow key={idx}>
                      <TableCell style={{...styles.tableCell, ...styles.tableCellTitle}}>{a.label}</TableCell>
                      <TableCell style={styles.tableCell}>
                        {
                          !this.props.showCount ? (
                            `${this.props.answersPercentage[idx]}`
                          ) : (
                            !this.props.showPercentage ? (
                              `${this.props.answersCount[idx]}`
                            ) : (
                              `${this.props.answersCount[idx]} (${this.props.answersPercentage[idx]})`
                            )
                          )
                        }
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  this.props.targetQuestionAlternatives.map((alt, idx) =>
                    <TableRow key={idx}>
                      <TableCell
                        key={`alt-body-${idx}`}
                        style={{
                          ...styles.tableCell,
                          ...styles.tableCellTitle,
                          ...styles.tableCellFixed
                        }}
                      >
                        {alt.label}
                      </TableCell>
                      {
                      this.props.baseQuestionAlternatives.map((alt2, idx2) =>
                        <TableCell key={idx2} style={styles.tableCell}>
                          {
                            (this.props.answersPercentage[idx] && this.props.answersPercentage[idx][idx2]) && (
                              !this.props.showCount ? (
                                `${this.props.answersPercentage[idx][idx2]}`
                              ) : (
                                !this.props.showPercentage ? (
                                  `${this.props.answersCount[idx][idx2]}`
                                ) : (
                                  `${this.props.answersCount[idx][idx2]} (${this.props.answersPercentage[idx][idx2]})`
                                )
                              )
                            )
                          }
                        </TableCell>
                      )
                      }
                    </TableRow>
                  )
                )
              }
            </TableBody>
          </Table>
        </Paper>
        <div style={{
          flexBasis: 48,

          display: 'flex',
          flexDirection: 'row',
        }}>
          <div style={{
            flex: 1,

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.showCount}
                  onChange={this.props.handleShowCountChange}
                  name="checkbox-count"
                  color="primary"
                />
              }
              label="Contagem"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.showPercentage}
                  onChange={this.props.handleShowPercentageChange}
                  name="checkbox-count"
                  color="primary"
                />
              }
              label="Porcentagem"
            />
          </div>
          <div style={{
            flex: 1,

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
            <Button
              color="primary"
              onClick={() => this.props.downloadAnswersTable()}
            >
              Baixar tabela
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

class SelectWithTitle extends Component {
  render() {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',

        marginLeft: 20,
        marginRight: 20,
      }}>
        <div style={{fontSize: 12}}>{this.props.label}</div>
        <FormControl>
          <Select
            id={`select-${this.props.label}`}
            value={this.props.value}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            style={{maxWidth: 200}}
          >
            {
              this.props.noneOption ? (
                <MenuItem value={-1}>Nenhuma</MenuItem>
              ) : null
            }
            {
              this.props.options.map((option, idx) =>
                <MenuItem key={option.id} value={idx}>{this.props.labels[idx]}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </div>
    );
  }
}

class Answers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      questions: [],
      targetQuestionIdx: -1,
      baseQuestionIdx: -1,
      targetQuestions: [],
      baseQuestions: [],
      baseQuestionAlternatives: [],
      targetQuestionAlternatives: [],
      answersPercentage: [],
      answersCount: [],
      showCount: true,
      showPercentage: true,

      // weighting
      questionsForWeighting: [],
      weighting: false,
      selectedWeightingQuestionIdx: -1,
      questionWeighting: {
        alternatives: []
      },
      weightingErrors: {
        emptyFieldError: true,
        sumIsOneHundredError: false,
        onlyNumberError: false
      },

      // filter
      questionsForFiltering: [],
      filtering: false,
      selectedFilteredQuestionIdx: -1,
      selectedFilteredAlternativeIdx: -1
    };

    this.handleChangeBaseQuestion = this.handleChangeBaseQuestion.bind(this);
    this.handleChangeTargetQuestion = this.handleChangeTargetQuestion.bind(this);

    this.handleWeightingChange = this.handleWeightingChange.bind(this);
    this.handleChangeWeightingQuestion = this.handleChangeWeightingQuestion.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.checkHasEmptyField = this.checkHasEmptyField.bind(this);
    this.checkOnlyNumbers = this.checkOnlyNumbers.bind(this);
    this.checkSumIsOneHundred = this.checkSumIsOneHundred.bind(this);
  
    this.handleFilteringChange = this.handleFilteringChange.bind(this);
    this.handleChangeFilteredQuestion = this.handleChangeFilteredQuestion.bind(this);
    this.handleChangeFilteredAlternative = this.handleChangeFilteredAlternative.bind(this);
    
    this.loadAnswers = this.loadAnswers.bind(this);
    this.downloadAnswers = this.downloadAnswers.bind(this);

    this.handleShowCountChange = this.handleShowCountChange.bind(this);
    this.handleShowPercentageChange = this.handleShowPercentageChange.bind(this);

    this.downloadAnswersTable = this.downloadAnswersTable.bind(this);
  }

  componentDidMount() {
    this.loadQuestions();
  }

  async downloadAnswers() {
    return api.getAnswersFile(this.props.answersDownloadLink);
    // console.log("Download link", this.props.answersDownloadLink);
    // try {
    //   window.fetch(`${process.env.REACT_APP_API_HOST}${this.props.answersDownloadLink}`,{
    //     headers: {
    //       'x-api-key': 'cEQloi1ZHG8n4MI4bOQvM2j6yrWqf7EH5mBp2Ynr'
    //     }
    //   })
    //     .then(res => {
    //       return res.blob();
    //     })
    //     .then(res => {
    //       let fname = `retorno_${from}_${to}_${nonProcessed ? 'only_new' : ''}.txt`;
    //       download(res, fname);
    //     })
    //     .catch(err => {
    //       alert('Erro ao baixar o arquivo');
    //     });
    //   await api.getAnswersFile({
    //     poll_id: this.props.pollId,
    //   });
    // } catch (err) {
    //   alert("Erro ao baixar o arquivo: " + err);
    // }
  }

  async loadQuestions() {
    try {
      let {questions} = await api.getPollQuestions({
        poll_id: this.props.pollId,
      });
      console.log("Questions", questions);

      let questionsWeight =
        questions.map(q => {
          let questionWeight = {
            id: q.id,
            body: q.body
          }

          if (q.type !== 'dissertative') {
            questionWeight.alternatives = q.alternatives.map(alt => ({id: alt.id, body: alt.body, weight: ''}))
          }

          return questionWeight;
        });

      let originalQuestionsWeight = JSON.parse(JSON.stringify(questionsWeight));

      this.setState({
        questions,
        baseQuestions: JSON.parse(JSON.stringify(questions.filter(q => q.type === 'multiple_choice'))),
        targetQuestions: JSON.parse(JSON.stringify(questions.filter(q => q.type !== 'dissertative'))),
        questionsForFiltering: JSON.parse(JSON.stringify(questions.filter(q => q.type !== 'dissertative'))),
        questionsForWeighting: JSON.parse(JSON.stringify(questions.filter(q => q.type === 'multiple_choice'))),
        questionsWeight,
        originalQuestionsWeight,
      });
    } catch (err) {
    }
  }

  async loadAnswers() {
    this.setState({loading: true});
    let {
      targetQuestions,
      baseQuestions,

      targetQuestionIdx,
      baseQuestionIdx,

      weighting,
      questionWeighting,

      filtering,
      selectedFilteredQuestionIdx,
      selectedFilteredAlternativeIdx
    } = this.state;

    let params = {
      target_question_id: targetQuestions[targetQuestionIdx].id,
      base_question_id: baseQuestionIdx !== -1 ? baseQuestions[baseQuestionIdx].id : null,
      poll_id: this.props.pollId,
    };

    if (weighting) {
      params.weighting = {
        question_id: questionWeighting.id,
        probabilities: questionWeighting.alternatives.map(alt => ({
          alternative_id: alt.id,
          probability: parseFloat(alt.weight)
        }))
      }
    }

    if (filtering) {
      params.filter_question_id = selectedFilteredQuestionIdx;
      params.filter_alternative_id = selectedFilteredAlternativeIdx;
    }

    try {
      let resp = await api.getAnswers(params);

      if (baseQuestionIdx === -1) {
        let {
          statistics,
          target_question_alternatives: targetQuestionAlternatives
        } = resp;

        let answersPercentage = [];
        let answersCount = [];

        let nrAnswerPercentage = 0;
        let nrAnswerCount = 0;

        statistics.forEach((st, idx) => {
          if (st.target_question_nr) {
            nrAnswerPercentage = parseFloat(st.percentage).toFixed(2);
            nrAnswerCount = st.count;
          } else {
            answersPercentage.push(parseFloat(st.percentage).toFixed(2));
            answersCount.push(st.count);
            targetQuestionAlternatives[idx].label = alphabet[idx];
          }
        });

        answersPercentage.push(nrAnswerPercentage);
        answersCount.push(nrAnswerCount);
        targetQuestionAlternatives.push({label: 'NR'})

        this.setState({
          answersPercentage,
          answersCount,
          targetQuestionAlternatives,
          loading: false
        });
      } else {
        let {
          statistics,
          base_question_alternatives: baseQuestionAlternatives,
          target_question_alternatives: targetQuestionAlternatives
        } = resp;

        let answersPercentage = [];
        let answersCount = [];

        for (let idxT in targetQuestionAlternatives) {

          answersPercentage.push([]);
          answersCount.push([]);
          targetQuestionAlternatives[idxT].label = alphabet[idxT];

          for (let idxB in baseQuestionAlternatives) {
            let st = statistics.find(st => (
              st.target_question_alternative_id === targetQuestionAlternatives[idxT].id &&
              st.base_question_alternative_id === baseQuestionAlternatives[idxB].id
            ));

            answersPercentage[idxT].push(parseFloat(st.percentage).toFixed(2));
            answersCount[idxT].push(st.count);
            baseQuestionAlternatives[idxB].label = alphabet[idxB];
          }

          let stNR = statistics.find(st => (
            st.target_question_alternative_id === targetQuestionAlternatives[idxT].id &&
            st.base_question_nr
          ));

          // NR
          if (stNR) {
            answersPercentage[idxT].push(parseFloat(stNR.percentage).toFixed(2));
            answersCount[idxT].push(stNR.count);
          } else {
            answersPercentage[idxT].push(0);
            answersCount[idxT].push(0);
          }
        }

        // NR
        answersPercentage.push([]);
        answersCount.push([]);
        baseQuestionAlternatives.forEach(bqAlt => {
          let stNR = statistics.find(st => (
            st.base_question_alternative_id === bqAlt.id &&
            st.target_question_nr
          ));

          if (stNR) {
            answersPercentage[answersPercentage.length - 1].push(parseFloat(stNR.percentage).toFixed(2));
            answersCount[answersPercentage.length - 1].push(stNR.count);
          } else {
            answersPercentage[answersPercentage.length - 1].push(0);
            answersCount[answersPercentage.length - 1].push(0);
          }
        });

        let stNR = statistics.find(st => st.base_question_nr && st.target_question_nr);

        if (stNR) {
          answersPercentage[answersPercentage.length - 1].push(parseFloat(stNR.percentage).toFixed(2));
          answersCount[answersPercentage.length - 1].push(stNR.count);
        } else {
          answersPercentage[answersPercentage.length - 1].push(0);
          answersCount[answersPercentage.length - 1].push(0);
        }

        targetQuestionAlternatives.push({label: 'NR'})
        baseQuestionAlternatives.push({label: 'NR'})

        this.setState({
          answersPercentage,
          answersCount,
        }, () => this.setState({
          baseQuestionAlternatives,
          targetQuestionAlternatives,
          loading: false
        }));
      }
    } catch (err) {
      console.log(err)
      this.setState({loading: false});
    }
  }

  handleChangeTargetQuestion(event) {
    this.setState({targetQuestionIdx: event.target.value});
  }

  handleChangeBaseQuestion(event) {
    this.setState({baseQuestionIdx: event.target.value});
  }

  handleWeightingChange(event) {
    this.setState({weighting: event.target.checked});
  }

  handleChangeWeightingQuestion(event) {
    let {questionsForWeighting} = this.state;
    let question = questionsForWeighting[event.target.value];

    let questionWeighting = {
      id: question.id,
      body: question.body,
      alternatives: question.alternatives.map(alt => ({id: alt.id, body: alt.body, weight: ''}))
    };

    this.setState({
      questionWeighting,
      selectedWeightingQuestionIdx: event.target.value,
    });
  }

  handleWeightChange(value, alternativeId) {
    let {questionWeighting} = this.state;

    questionWeighting.alternatives.forEach(a => {
      if (a.id === alternativeId) {
        a.weight = value;
        this.setState({questionWeighting});
      }
    })
  }

  empty(text) {
    return text == null ||text == undefined || text.length === 0 || text === '';
  }

  checkOnlyNumbers() {
    let check = false;
    this.state.questionWeighting.alternatives.forEach(alt => {
      let weightNumber = parseFloat(alt.weight.replace(',', '.'));

      if (isNaN(weightNumber)) {
        check = true;
      }
    });

    let {weightingErrors} = this.state;
    weightingErrors.onlyNumberError = check;

    this.setState({weightingErrors});
  }

  checkHasEmptyField() {
    let emptyFieldsCount = this.state.questionWeighting.alternatives.filter(a => this.empty(a.weight)).length;
    let check = emptyFieldsCount > 0;

    let {weightingErrors} = this.state;
    weightingErrors.emptyFieldError = check;

    this.setState({weightingErrors});
  }

  checkSumIsOneHundred() {
    let sum = 0;
    this.state.questionWeighting.alternatives.forEach(alt => {
      let weightNumber = parseFloat(alt.weight.replace(',', '.'));
      if (!isNaN(weightNumber)) {
        sum += weightNumber;
      }
    });

    let emptyFieldsCount = this.state.questionWeighting.alternatives.filter(a => this.empty(a.weight)).length;
    let check = Math.round(sum) != 100 && emptyFieldsCount !== this.state.questionWeighting.alternatives.length;

    let {weightingErrors} = this.state;
    weightingErrors.sumIsOneHundredError = check;

    this.setState({weightingErrors});
  }

  handleFilteringChange(event) {
    this.setState({filtering: event.target.checked});
  }

  handleChangeFilteredQuestion(event) {
    this.setState({selectedFilteredQuestionIdx: event.target.value});
  }

  handleChangeFilteredAlternative(event) {
    this.setState({selectedFilteredAlternativeIdx: event.target.value});
  }

  handleShowCountChange(event) {
    let {checked} = event.target;
    let {showPercentage} = this.state;

    if (!checked && !showPercentage) {
      showPercentage = true;
    }

    this.setState({showCount: checked, showPercentage});
  }

  handleShowPercentageChange(event) {
    let {checked} = event.target;
    let {showCount} = this.state;

    if (!checked && !showCount) {
      showCount = true;
    }

    this.setState({showCount, showPercentage: checked});
  }

  downloadAnswersTable() {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const table = document.getElementById('answers-table');
    let fileName = `Pesquisa ${this.props.pollId} - Respostas`;

    let targetQuestion = this.state.targetQuestions[this.state.targetQuestionIdx].body;

    if (this.state.baseQuestionIdx !== - 1) {
      let baseQuestion = this.state.baseQuestions[this.state.baseQuestionIdx].body;
      fileName = `${fileName} Q${targetQuestion} e Q${baseQuestion}`;
    } else {
      fileName = `${fileName} Q${targetQuestion}`;
    }

    const ws = XLSX.utils.table_to_sheet(table);
    const wb = { Sheets: { 'Respostas': ws }, SheetNames: ['Respostas'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{
          flex: 1,
          display: this.props.hidden ? 'none' : 'flex',
          flexDirection: 'column',
        }}>
          Carregando
        </div>
      )
    }
    return (
      <div
        style={{
          flex: 1,
          display: this.props.hidden ? 'none' : 'flex',
          flexDirection: 'column',

          paddingTop: 20,
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 40,
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',

          height: 64
        }}>
          <div style={{flex: 1}}>
            <Typography variant="h5">
              Respostas
            </Typography>
          </div>
          <div style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}>
            <div style={{flexBasis: 200}}>
              <SelectWithTitle
                label="Questão alvo"
                value={this.state.targetQuestionIdx}
                onChange={this.handleChangeTargetQuestion}
                options={this.state.targetQuestions}
                labels={this.state.targetQuestions.map(q => q.position)}
              />
            </div>
            <div style={{flexBasis: 200}}>
              <SelectWithTitle
                noneOption
                label="Questão base"
                value={this.state.baseQuestionIdx}
                onChange={this.handleChangeBaseQuestion}
                options={this.state.baseQuestions}
                labels={this.state.targetQuestions.map(q => q.position)}
              />
            </div>
          </div>
          <div style={{
            flexBasis: 100,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
            <Tooltip title="Baixar Respostas">
              <Fab
                size="small"
                color="primary"
                aria-label="Baixar Respostas"
                style={{marginLeft: 20}}
                onClick={this.downloadAnswers}
              >
                <GetAppIcon fontSize="small"/>
              </Fab>
            </Tooltip>
          </div>
        </div>
        <div style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',

          paddingTop: 20
        }}>
          <div style={{
            width: 0,
            
            flex: 4,
            display: 'flex',
            flexDirection: 'column',

            paddingLeft: 20,
          }}>
            {
              this.state.answersPercentage.length > 0 ? (
                <AnswersTable
                  baseQuestionAlternatives={this.state.baseQuestionAlternatives}
                  targetQuestionAlternatives={this.state.targetQuestionAlternatives}
                  answersPercentage={this.state.answersPercentage}
                  answersCount={this.state.answersCount}
                  showCount={this.state.showCount}
                  showPercentage={this.state.showPercentage}
                  handleShowCountChange={this.handleShowCountChange}
                  handleShowPercentageChange={this.handleShowPercentageChange}
                  downloadAnswersTable={this.downloadAnswersTable}
                />
              ) : (
                <div style={{
                  color: '#545454'
                }}>
                  Selecione uma questão alvo...
                </div>
              )
            }
          </div>
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',

            paddingLeft: 20,
          }}>
            <QuestionsWeighting
              weighting={this.state.weighting}
              handleWeightingChange={this.handleWeightingChange}
              questions={this.state.questionsForWeighting}
              questionWeighting={this.state.questionWeighting}
              selectedWeightingQuestionIdx={this.state.selectedWeightingQuestionIdx}
              handleChangeWeightingQuestion={this.handleChangeWeightingQuestion}
              handleWeightChange={this.handleWeightChange}
              checkOnlyNumbers={this.checkOnlyNumbers}
              checkHasEmptyField={this.checkHasEmptyField}
              checkSumIsOneHundred={this.checkSumIsOneHundred}
              emptyFieldError={this.state.weightingErrors.emptyFieldError}
              sumIsOneHundredError={this.state.weightingErrors.sumIsOneHundredError}
              onlyNumberError={this.state.weightingErrors.onlyNumberError}
            />
          </div>
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

            paddingLeft: 20,
          }}>
            <QuestionFilter
              filtering={this.state.filtering}
              handleFilteringChange={this.handleFilteringChange}
              questions={this.state.questionsForFiltering}
              handleChangeFilteredQuestion={this.handleChangeFilteredQuestion}
              handleChangeFilteredAlternative={this.handleChangeFilteredAlternative}
              selectedFilteredQuestionIdx={this.state.selectedFilteredQuestionIdx}
              selectedFilteredAlternativeIdx={this.state.selectedFilteredAlternativeIdx}
            />
            <Button
              color="primary"
              variant="contained"
              disabled={
                this.state.targetQuestionIdx === -1 ||
                (this.state.weighting && (
                  (this.state.selectedWeightingQuestionIdx === -1) ||
                  (this.state.weightingErrors.emptyFieldError || this.state.weightingErrors.sumIsOneHundredError || this.state.weightingErrors.onlyNumberError)
                )) ||
                (this.state.filtering && (this.state.selectedFilteredQuestionIdx === -1 || this.state.selectedFilteredAlternativeIdx === -1))
              }
              onClick={this.loadAnswers}
            >
              Aplicar
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Answers;
